import React from 'react';
import { usePerson } from '~/src/game';
import { getEmoji } from '~/src/utils/getEmoji';
import { $ui } from '../state';

export const InlinePerson = React.memo((props: { id: string }) => {
  const person = usePerson(props.id);

  const onClick = () => {
    $ui.selectedPersonId = props.id;
  };

  return (
    <span onClick={onClick}>
      {getEmoji(person)}
      <h4 style={{ margin: 0, display: 'inline' }}>{person.name}</h4>
    </span>
  );
});
