export const getAttrBaseValue = (attr: Attribute, age: number): number => {
  if (typeof attr.value.base === 'number') {
    return attr.value.base;
  }

  if (attr.value.base[age] === undefined) {
    return attr.value.base[attr.value.base.length - 1];
  }

  return attr.value.base[age];
};
