import { proxy, useSnapshot } from 'valtio';

export const $game = proxy<Game>({
  tick: 0,
  personsAlive: 0,
  stage: 'pause',
});

export const $list = proxy<List>({
  personIds: [],
  graveyardIds: [],
});

export const $persons = proxy<Record<string, Person>>({});

export const useGame = () => useSnapshot($game);

export const useList = () => useSnapshot($list);

export const usePerson = (id: string) => useSnapshot($persons)[id];

export const usePersons = () => useSnapshot($persons);

export const $setup = proxy<Setup>({
  initialPersons: 8,
  realism: false,
  interval: 500,
});

export const useSetup = () => useSnapshot($setup);
