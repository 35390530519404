import { $setup } from '~/src/game';
import { spawnPerson } from './person/lifecycle';

export const startup = () => {
  let count = $setup.initialPersons;

  const realism = $setup.realism;

  while (count--) {
    spawnPerson({
      parentIds: [],
      perkIds: realism ? [] : ['settler'],
      sex: realism ? undefined : count % 2 ? '♀' : '♂',
    });
  }
};
