/**
 * {
 *   0: 1
 *   2: 0.5,
 *   4: 0.3,
 * }
 *
 * --->
 *
 * [1, 0.75, 0.5, 0.4, 0.3]
 */
export const interpolate = (points: Record<number, number>): number[] => {
  // пока что халтурю и не интерполирую
  // а просто проставляю текущее значение пока могу
  const keys = (Object.keys(points).sort() as unknown) as number[];

  const last = keys[keys.length - 1];

  const result = Array(last + 1);

  let value = keys[0];

  for (let i = 0; i <= last; i++) {
    value = points[i] || value;

    result[i] = value;
  }

  return result;
};
