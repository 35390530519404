import { $game, $persons, $list } from '~/src/game';
import { getDeathProbability } from '~/src/actions/utils/getDeathProbability';
import { roll } from '~/src/utils/roll';
import { killPerson, spawnPerson } from './person/lifecycle';
import { getBirthProbability } from './utils/getBirthProbability';
import { getMarriageProbability } from './utils/getMarriageProbability';
import { forEveryPerson } from './utils/forEveryPerson';
import { matchPerson } from './person/match';

export const tick = () => {
  invalidateList();

  $game.tick++;

  forEveryPerson((person) => {
    person.age++;
  });

  forEveryPerson((person) => {
    if (roll(getBirthProbability(person))) {
      spawnPerson({
        parentIds: [person.id, person.partnerId!],
      });
    }

    if (roll(getMarriageProbability(person))) {
      matchPerson({
        id: person.id,
      });
    }

    if (roll(getDeathProbability(person))) {
      killPerson({
        id: person.id,
      });
    }
  });

  if ($game.personsAlive === 0) {
    $game.stage = 'gameover';
  }
};

function invalidateList() {
  if ($list.personIds.length === 0) {
    return;
  }

  // смотрим, живы ли первые 4
  for (let i = 0; i < 4; i++) {
    const id = $list.personIds[i];

    // если хотя бы один жив, ничего не делаем
    if (!$persons[id].diedAt) {
      return;
    }
  }

  // если все мертвы, удаляем первые 8 персонажей из списка
  $list.personIds = $list.personIds.slice(4);
}
