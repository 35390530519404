export const ifValidModifier = (person: Person, modifier: Modifier) => {
  if (modifier.from !== undefined && person.age < modifier.from) {
    return false;
  }

  if (modifier.to !== undefined && person.age > modifier.to) {
    return false;
  }

  return true;
};
