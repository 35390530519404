import { getAttrValue } from '~/src/utils/getAttrValue';

export const getMarriageProbability = (person: Person) => {
  // разводы невозможны
  if (person.partnerId) {
    return 0;
  }

  return getAttrValue(person, 'marriage_probability');
};
