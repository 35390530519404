import React, { useMemo } from 'react';
import { useGame, usePersons, useSetup } from '~/src/game';
import { InlinePerson } from './InlinePerson';

export const Stats = React.memo(() => {
  const persons = usePersons();
  const tick = useGame().tick;
  const { initialPersons, realism } = useSetup();

  const stats = useMemo(() => getStats(Object.values(persons)), []);
  const totalPersons = Object.keys(persons).length;
  const points = tick + totalPersons;

  return (
    <div>
      {stats.persons.map((stat, index) => {
        return (
          <div key={`${index}`}>
            {stat.text} ({stat.value}): <InlinePerson id={stat.personId} />
          </div>
        );
      })}
      <br />
      <br />
      {stats.numbers.map((stat, index) => {
        return (
          <div key={`${index}`}>
            {stat.text}: {stat.value}
          </div>
        );
      })}
      <br />
      <br />
      <div>
        Очков: <b>{points}</b> ({tick} лет + {totalPersons} жителей), а начиналось с{' '}
        {initialPersons} жителей{realism ? ' (реализм)' : ''}.
      </div>
    </div>
  );
});

function getStats(persons: Person[]) {
  let oldest = persons[0];
  let oldestValue = -Infinity;

  let fertilest = persons[0];
  let fertilestValue = -Infinity;

  let marriagest = persons[0];
  let marriagestValue = -Infinity;

  let ageSum = 0;

  let maxGeneration = 0;

  persons.forEach((person) => {
    ageSum += person.age;

    if (person.age > oldestValue) {
      oldestValue = person.age;
      oldest = person;
    }

    const childCount = person.childIds.length;

    if (childCount > fertilestValue) {
      fertilestValue = childCount;
      fertilest = person;
    }

    const marriageCount = person.exPartnerIds.length + (person.partnerId ? 1 : 0);

    if (marriageCount > marriagestValue) {
      marriagestValue = marriageCount;
      marriagest = person;
    }

    if (person.generation > maxGeneration) {
      maxGeneration = person.generation;
    }
  });

  return {
    persons: [
      {
        text: 'Прожил дольше всех',
        value: oldestValue,
        personId: oldest.id,
      },
      {
        text: 'Родила больше всех',
        value: fertilestValue,
        personId: fertilest.id,
      },
      {
        text: 'Было больше всех супругов',
        value: marriagestValue,
        personId: marriagest.id,
      },
    ],

    numbers: [
      {
        text: 'Средняя продолжительность жизни',
        value: Math.round(ageSum / persons.length),
      },
      {
        text: 'Существовало поколений',
        value: maxGeneration,
      },
    ],
  };
}
