export function getEmoji(person: Person) {
  const ages = {
    baby: 4,
    child: 16,
    adult: 50,
  };

  if (person.age < ages.baby) {
    return '👶';
  }

  if (person.age < ages.child) {
    return person.sex === '♀' ? '👧' : '👦';
  }

  if (person.age < ages.adult) {
    return person.sex === '♀' ? '👩' : '👨';
  }

  return person.sex === '♀' ? '👵' : '👴';
}
