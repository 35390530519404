import { getAttrValue } from '~/src/utils/getAttrValue';

export const getBirthProbability = (person: Person) => {
  // если не замужем или муж умер, родить нельзя
  if (!person.partnerId) {
    return 0;
  }

  const value = getAttrValue(person, 'birth_probability');

  // у мужчин вероятность родить намного меньше
  if (person.sex === '♂') {
    return value / 3333;
  }

  return value;
};
