const yay = <T>(map: { [K in keyof T]: Trait<string> }) => map;

export const traits = yay({
  blood_type: {
    ui: {
      title: 'Группа крови',
    },

    gene: 'AB0',

    variants: {
      ab: {
        ui: {
          title: 'AB',
        },

        cond: 'AB',
      },
      a: {
        ui: {
          title: 'A',
        },

        cond: 'A',
      },
      b: {
        ui: {
          title: 'B',
        },

        cond: 'B',
      },
      0: {
        ui: {
          title: '0',
        },

        cond: '00',
      },
    },
  },

  freckles: {
    ui: {
      title: 'Веснушки',
    },

    gene: 'Ff',

    variants: {
      yes: {
        ui: {
          title: 'Есть веснушки',
        },

        cond: 'F',
      },

      no: {
        ui: {
          title: 'Нет веснушек',
        },

        cond: 'ff',
      },
    },
  },
});

export type TraitId = keyof typeof traits;

export const traitIds = Object.keys(traits) as TraitId[];
