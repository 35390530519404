import { interpolate } from '~/src/utils/interpolate';

const yay = <T>(map: { [K in keyof T]: Attribute }) => map;

export const attrs = yay({
  death_probability: {
    ui: {
      title: 'Вероятность умереть',
    },
    value: {
      base: interpolate({
        0: 0.01,
        10: 0.001,
        20: 0.002,
        30: 0.005,
        40: 0.01,
        50: 0.05,
        60: 0.1,
        70: 0.2,
        80: 0.3,
        90: 0.5,
      }),
    },
  },

  marriage_probability: {
    ui: {
      title: 'Вероятность жениться',
    },
    value: {
      base: interpolate({
        0: 0,
        10: 0.001,
        12: 0.01,
        15: 0.1,
        18: 0.2,
        50: 0.1,
        60: 0.05,
      }),
    },
  },

  birth_probability: {
    ui: {
      title: 'Вероятность родить',
    },
    value: {
      base: interpolate({
        0: 0,
        14: 0.05,
        20: 0.1,
        30: 0.075,
        40: 0.05,
        50: 0.02,
        60: 0.005,
        70: 0.0005,
      }),
    },
  },

  /**
   * Фертильность отличается от вероятности родить.
   *
   * Представь, что
   *
   * - фертильность — физическая возможность
   * - вероятность родить — желание и случай
   *
   * Бесплодие — это нулевая фертильность.
   */
  fertility: {
    ui: {
      title: 'Фертильность',
    },
    value: {
      base: interpolate({
        0: 0,
        12: 0.1,
        14: 0.5,
        16: 1,
        40: 0.8,
        50: 0.6,
        60: 0.3,
        70: 0.03,
      }),
      min: 0,
      max: Infinity,
    },
  },
});

export type AttrKey = keyof typeof attrs;
