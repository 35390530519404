export const roll = (ratio: number) => {
  return Math.random() < ratio;
};

export const rand = (from = 0, to = 1) => {
  return Math.floor(Math.random() * (to - from + 1) + from);
};

export function draw<T>(arr: T[]) {
  return arr[rand(0, arr.length - 1)];
}
