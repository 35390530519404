import { $game, $list, $persons } from '~/src/game';
import { deduplicate } from '~/src/utils/deduplicate';
import { makeId } from '~/src/utils/makeId';
import { makePersonName } from '~/src/utils/makePersonName';
import { makeSex } from '~/src/utils/makeSex';
import { getSpawnGeneration } from '../utils/getSpawnGeneration';
import { getSpawnGenes } from '../utils/getSpawnGenes';
import { getSpawnPerks } from '../utils/getSpawnPerks';
import { getTraitsForGenes } from '../utils/getTraitsForGenes';

export const spawnPerson = (params: Partial<Person>) => {
  const {
    parentIds = [],
    childIds = [],
    exPartnerIds = [],
    generation = 1,
    perkIds = [],
    sex = makeSex(),
    age = 0,
  } = params;

  const id = makeId();

  const generationFromParents = getSpawnGeneration(parentIds);

  const randPerkIds = getSpawnPerks(parentIds);

  const genes = getSpawnGenes(parentIds);

  const traits = getTraitsForGenes(genes);

  const subject: Person = {
    id,
    name: makePersonName(),
    bornAt: $game.tick,
    parentIds,
    exPartnerIds,
    childIds,
    age,
    generation: generationFromParents || generation,
    sex,
    perkIds: deduplicate([...perkIds, ...randPerkIds]),
    genes,
    traits,
  };

  $persons[id] = subject;
  $list.personIds.push(id);

  parentIds.forEach((parentId) => $persons[parentId].childIds.push(id));

  $game.personsAlive++;

  return subject;
};

export const killPerson = (params: { id: string }) => {
  const { id } = params;

  const subject = $persons[id];

  subject.diedAt = $game.tick;

  $list.graveyardIds.push(id);

  const partner = subject.partnerId && $persons[subject.partnerId];

  if (partner) {
    partner.partnerId = undefined;
    partner.exPartnerIds.push(id);

    // у мертвых не делаем бывших
    // subject.partnerId = undefined;
    // subject.exPartnerIds.push(partner.id);
  }

  $game.personsAlive--;

  return subject;
};
