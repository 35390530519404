import React from 'react';
import { usePerson, useSetup } from '~/src/game';
import { $ui } from '~/src/ui/state';
import { getEmoji } from '~/src/utils/getEmoji';

export const Person = React.memo((props: { id: string }) => {
  const person = usePerson(props.id);
  const isDead = !!person.diedAt;

  const onClick = () => {
    $ui.selectedPersonId = props.id;
  };

  return (
    <section
      onClick={onClick}
      style={{
        background: '#eee',
        boxSizing: 'border-box',
        padding: 4,
        margin: 4,
        textAlign: 'center',
        width: 'calc(25% - 8px)',
        position: 'relative',
        transition: 'opacity 0.5s, filter 0.5s',
        ...(isDead ? { opacity: 0.33, filter: 'grayscale(1)' } : {}),
      }}
    >
      <div>{getEmoji(person)}</div>
      <h4 style={{ margin: 0 }}>{person.name}</h4>
      <div style={{ fontSize: '12px' }}>
        {isDead ? `умер в ${person.age}` : `лет: ${person.age}`}
      </div>
      {!!person.childIds.length && (
        <div style={{ position: 'absolute', top: 0, right: 0, fontSize: '9px', padding: '0 2px' }}>
          {person.childIds.length}
        </div>
      )}
      {!!person.partnerId && (
        <div style={{ position: 'absolute', top: 0, left: 0, fontSize: '9px', padding: '0 2px' }}>
          +
        </div>
      )}
    </section>
  );
});
