export const maxBy = <T>(arr: T[], predicate: (x: T) => number): [T, number] | undefined => {
  if (arr.length === 0) {
    return undefined;
  }

  let maxElement = arr[0];
  let maxValue = predicate(arr[0]);

  for (let i = 1; i < arr.length; i++) {
    const value = predicate(arr[i]);

    if (value > maxValue) {
      maxValue = value;
      maxElement = arr[i];
    }
  }

  return [maxElement, maxValue];
};
