import { TraitId, traits } from '~/src/config/traits';

export const getTraitsForGenes = (genes: Person['genes']): Person['traits'] => {
  const result: any = {};

  Object.entries(genes).forEach(([traitId, gene]) => {
    const variants = Object.entries(traits[traitId as TraitId].variants);

    for (const [variantName, variantConfig] of variants) {
      if (matches(gene, variantConfig.cond)) {
        result[traitId] = variantName;
        break;
      }
    }
  });

  return result;
};

/**
 * gene: AB
 * cond: AB | A | B | 00
 */
function matches(gene: string, cond: string) {
  // AB | 00
  if (cond.length === 2) {
    return gene === cond;
  }

  // A | B
  return gene.includes(cond);
}
