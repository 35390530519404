import { AttrKey } from './attrs';

const yay = <T>(map: { [K in keyof T]: Perk<AttrKey> }) => map;

export const perks = yay({
  settler: {
    ui: {
      title: 'Поселенец',
    },

    modifiers: [
      // не умрёт в детстве
      {
        attributeId: 'death_probability',
        to: 32,

        multiply: 0,
      },
    ],

    rarity: 0,
  },

  long_liver: {
    ui: {
      title: 'Долгожитель',
    },

    modifiers: [
      // вероятность умереть в 2 раза ниже
      {
        attributeId: 'death_probability',
        multiply: 0.5,
      },
    ],

    rarity: 1 / 100,
  },

  hero_parent: {
    ui: {
      title: 'Родитель-герой',
    },

    modifiers: [
      // вероятность родить в 2 раза выше
      {
        attributeId: 'birth_probability',
        multiply: 2,
      },
    ],

    rarity: 1 / 100,
  },
});

export type PerkId = keyof typeof perks;
