import { getMarriageProbability } from './getMarriageProbability';

export const getMatchRate = (self: Person, target: Person) => {
  if (self.sex === target.sex) {
    return 0;
  }

  let base = 1;

  if (Math.abs(self.bornAt - target.bornAt) > 10) {
    base = 0.8;
  }

  return base * getMarriageProbability(target); // вероятность работает в обе стороны
};
