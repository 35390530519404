import { draw, rand, roll } from './roll';

export const makePersonName = () => {
  let name = '';

  let length = rand(1, 4) + rand(1, 4);

  let vowsCount = 0;

  while (length--) {
    if (roll(0.6)) {
      name += draw(cons);
    } else {
      name += draw(vows);
      vowsCount += 1;
    }
  }

  if (!vowsCount) {
    name += draw(vows);
  }

  return capitalizeFirstLetter(name);
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const cons = [
  'b',
  'c',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'm',
  'n',
  'p',
  'q',
  'r',
  's',
  't',
  'w',
  'x',
  'z',
];

const vows = ['a', 'e', 'i', 'o', 'u', 'y', 'æ', 'ø', 'å'];
