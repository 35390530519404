import { PerkId, perks } from '~/src/config/perks';
import { roll } from '~/src/utils/roll';

const entries = Object.entries(perks);

// @TODO перки должны зависеть от перков родителей (наследование)
export const getSpawnPerks = (_parentIds: string[]): PerkId[] => {
  const perkIds: PerkId[] = [];

  entries.forEach(([id, perk]) => {
    if (roll(perk.rarity)) {
      perkIds.push(id as PerkId);
    }
  });

  return perkIds;
};
