import { TraitId, traitIds, traits } from '~/src/config/traits';
import { $persons } from '~/src/game';
import { draw } from '~/src/utils/roll';

export const getSpawnGenes = (parentIds: string[]): Person['genes'] => {
  const parent1 = $persons[parentIds[0]];
  const parent2 = $persons[parentIds[1]];

  const genes: any = {};

  traitIds.forEach((traitId) => {
    if (!parent1 || !parent2) {
      genes[traitId] = getDefaultGene(traitId);
      return;
    }

    const gene1 = parent1.genes[traitId].split('');
    const gene2 = parent2.genes[traitId].split('');

    const result = [draw(gene1), draw(gene2)].sort().join('');

    genes[traitId] = result;
  });

  return genes;
};

const getDefaultGene = (traitId: TraitId) => {
  const gene = traits[traitId].gene.split('');

  return [draw(gene), draw(gene)].sort().join('');
};
