import { getMatchRate } from '~/src/actions/utils/getMatchRate';
import { $persons } from '~/src/game';
import { maxBy } from '~/src/utils/maxBy';
import { draw } from '~/src/utils/roll';

export const matchPerson = (params: { id: string }) => {
  const { id } = params;

  const person = $persons[id];

  const candidates: Person[] = [];

  // откинем явно ненужных — себя и мёртвых
  // вся остальная логика в getMatchRate
  const persons = Object.values($persons).filter((p) => !p.diedAt && p.id !== id);

  // это если остался один живой персонаж
  if (!persons.length) {
    return;
  }

  // сначала случайно выберем несколько кандидатов
  let count = 5;
  while (count--) {
    candidates.push(draw(persons));
  }

  if (!candidates.length) {
    return;
  }

  // потом возьмём самого привлекательного из них
  const match = maxBy(candidates, (candidate) => getMatchRate(person, candidate));

  if (!match || !match[0] || match[1] === 0) {
    return;
  }

  match[0].partnerId = id;
  person.partnerId = match[0].id;
};
