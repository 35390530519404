import { AttrKey, attrs } from '~/src/config/attrs';
import { getAttrBaseValue } from './getAttrBaseValue';
import { getAttrModifiers } from './getAttrModifiers';
import { ifValidModifier } from './ifValidModifier';

export const getAttrValue = (person: Person, attrKey: AttrKey) => {
  const base = getAttrBaseValue(attrs[attrKey], person.age);

  const mods = getAttrModifiers(person, attrKey).filter((mod) => ifValidModifier(person, mod));

  let result = base;

  mods.forEach((mod) => {
    if (mod.multiply !== undefined) {
      result = result * mod.multiply;
    }

    if (mod.add !== undefined) {
      result = result + mod.add;
    }
  });

  return result;
};
