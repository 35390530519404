import { AttrKey } from '~/src/config/attrs';
import { perks } from '~/src/config/perks';

export const getAttrModifiers = (person: Person, attrKey: AttrKey) => {
  let mods: Modifier[] = [];

  person.perkIds.forEach((perkId) => {
    perks[perkId].modifiers.forEach((modifier) => {
      if (modifier.attributeId === attrKey) {
        mods.push(modifier);
      }
    });
  });

  return mods;
};
