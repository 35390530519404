import { makePersonName } from './makePersonName';
import { draw } from './roll';

export const makeSettlementName = () => {
  return makePersonName() + draw(suffixes);
};

const suffixes = [
  'port',
  'clare',
  'ley',
  'view',
  'folk',
  'sex',
  'karta',
  'grad',
  'hampton',
  'stead',
  'stedt',
  'stätt',
  'dorf',
  'wych',
  'wick',
  'wyke',
  'wich',
  'thorpe',
  'thorp',
  'ceter',
  'ham',
  'caster',
  'by',
  'dale',
  'field',
  'ford',
  'town',
  'bury',
  'chester',
  'ton',
  'burgh',
  'burg',
  'ville',
];
