const x: Array<[number, string]> = [
  [1000, 'M'],
  [900, 'CM'],
  [500, 'D'],
  [400, 'CD'],
  [100, 'C'],
  [90, 'XC'],
  [50, 'L'],
  [40, 'XL'],
  [10, 'X'],
  [9, 'IX'],
  [5, 'V'],
  [4, 'IV'],
  [1, 'I'],
];

export function getGenerationName(generation: number) {
  if (generation > 3999) {
    return '???';
  }

  let result = '';
  while (generation > 0) {
    const y = x.find((item) => item[0] <= generation)!;
    generation -= y[0];
    result += y[1];
  }

  return result;
}
