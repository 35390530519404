import { useEffect, useMemo, useState } from 'react';
import { startup } from '~/src/actions/startup';
import { tick } from '~/src/actions/tick';
import { Settlement } from '~/src/ui/game/Settlement';
import { $game, $setup, useGame } from '~/src/game';
import { Stats } from './game/Stats';
import { useUi } from './state';
import { PersonProfile } from './game/PersonProfile';

function App() {
  const [stage, setStage] = useState<'setup' | 'startup' | 'game'>('setup');

  const onStartClick = (count: number, realism: boolean, interval?: number) => {
    $setup.initialPersons = count;
    $setup.realism = realism;
    if (interval) {
      $setup.interval = interval;
    }

    setStage('startup');
  };

  useEffect(() => {
    if (stage === 'startup') {
      console.log('startup');
      startup();
      setStage('game');
    }
  }, [stage]);

  if (stage === 'setup') {
    return <Setup onStartClick={onStartClick} />;
  }

  if (stage === 'startup') {
    return <div>загрузка...</div>;
  }

  return <Game />;
}

const Setup = (props: {
  onStartClick: (count: number, realism: boolean, interval?: number) => void;
}) => {
  const { onStartClick } = props;

  return (
    <div className="app">
      <h5>
        Просто смотри как рождаются и умирают жители и надейся, что поселение проживёт как можно
        дольше
      </h5>
      <h6>Выбери режим и игра начнётся:</h6>
      <div style={{ marginBottom: 16 }}>
        <button onClick={() => onStartClick(8, false)}>Нормальный</button>
        <p>
          8 поселенцев с особым перком, который поможет им выжить, чтобы игра не закончилась слишком
          рано
        </p>
        <hr />
      </div>
      <div style={{ marginBottom: 16 }}>
        <button onClick={() => onStartClick(8, true)}>Реализм</button>
        <p>8 поселенцев без особых признаков</p>
        <hr />
      </div>

      <div style={{ marginBottom: 16 }}>
        <button onClick={() => onStartClick(48, false, 100)}>Наркоман</button>
        <p>48 поселенцев, ускоренная симуляция</p>
      </div>
    </div>
  );
};

const Game = () => {
  const game = useGame();

  useEffect(() => {
    $game.stage = 'play';

    setInterval(() => {
      if ($game.stage === 'play') {
        tick();
      }
    }, $setup.interval);
  }, []);

  useEffect(() => {
    if (game.stage === 'gameover') {
      window.scrollTo(0, 0);
    }
  }, [game.stage]);

  const ui = useUi();

  return (
    <div className="app">
      <Controls />
      <header>
        Год: {game.tick}
        {game.stage === 'pause' ? ' (пауза)' : ''}
      </header>
      {game.stage === 'gameover' && (
        <div style={{ padding: 16, border: '2px solid #776ca8', margin: '16px 0' }}>
          Игра окончена, все умерли 💀
          <br />
          <br />
          <Stats />
          <br />
          <br />
          <button onClick={() => window.location.reload()}>Начать заново</button>
        </div>
      )}
      <div>
        {ui.selectedPersonId && <PersonProfile id={ui.selectedPersonId} />}
        <Settlement />
      </div>
    </div>
  );
};

const Controls = () => {
  const game = useGame();

  const symbol = useMemo(() => {
    switch (game.stage) {
      case 'play':
        return '⏸';
      case 'pause':
        return '▶';
      case 'gameover':
        return '🔄';
    }
  }, [game.stage]);

  const onClick = () => {
    switch (game.stage) {
      case 'play':
        $game.stage = 'pause';
        break;
      case 'pause':
        $game.stage = 'play';
        break;
      case 'gameover':
        window.location.reload();
        break;
    }
  };

  return (
    <div
      onClick={onClick}
      style={{
        height: 32,
        width: 32,
        lineHeight: '32px',
        textAlign: 'center',
        border: '2px solid #ddd',
        cursor: 'pointer',
      }}
    >
      {symbol}
    </div>
  );
};

export default App;
