import React, { useState } from 'react';
import { useGame, useList } from '~/src/game';
import { makeSettlementName } from '~/src/utils/makeSettlementName';
import { Person } from './Person';

export const Settlement = React.memo(() => {
  const list = useList();
  const game = useGame();

  const name = useState(() => makeSettlementName());

  return (
    <section>
      <header>
        <h5>
          {name} ({game.personsAlive} жителей){' '}
          {list.graveyardIds.length < 16
            ? list.graveyardIds.map((_) => '✝')
            : `✝×${list.graveyardIds.length}`}
        </h5>
      </header>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {list.personIds.map((id) => (
          <Person id={id} key={id} />
        ))}
      </div>
    </section>
  );
});
