import React from 'react';
import { PerkId, perks } from '~/src/config/perks';
import { TraitId, traits } from '~/src/config/traits';
import { usePerson } from '~/src/game';
import { $ui } from '~/src/ui/state';
import { getEmoji } from '~/src/utils/getEmoji';
import { getGenerationName } from '~/src/utils/getGenerationName';
import { InlinePerson } from './InlinePerson';

export const PersonProfile = React.memo<{ id: string }>(({ id }) => {
  const person = usePerson(id);

  const onCloseClick = () => {
    $ui.selectedPersonId = undefined;
  };

  return (
    <div style={{ margin: '16px 0', padding: 16, border: '2px solid #eee', position: 'relative' }}>
      <h3 style={{ marginTop: 0 }}>{person.name}</h3>
      <div style={{ fontSize: '24px' }}>{getEmoji(person)}</div>
      <dl>
        <dt>Возраст:</dt>
        <dd>
          {person.age}
          {person.diedAt && ' (умер)'}
        </dd>

        <dt>Пол:</dt>
        <dd>{person.sex}</dd>

        <dt>Перки:</dt>
        <dd>{person.perkIds.map((perk: PerkId) => perks[perk].ui.title).join(', ')}</dd>

        <dt>Черты:</dt>
        <dd>
          {Object.entries(person.traits)
            .map(
              ([key, value]) =>
                `${traits[key as TraitId].ui.title}: ${
                  traits[key as TraitId].variants[value].ui.title
                } [${person.genes[key as TraitId]}]`,
            )
            .join(', ')}
        </dd>

        <dt>Поколение:</dt>
        <dd style={{ fontFamily: 'serif' }}>{getGenerationName(person.generation)}</dd>

        <dt>Родители</dt>
        <dd>
          {person.parentIds.map((parentId) => (
            <InlinePerson id={parentId} key={parentId} />
          ))}
        </dd>

        <dt>Супруги</dt>
        <dd>
          {person.partnerId && <InlinePerson id={person.partnerId} />}
          {!!person.exPartnerIds.length && (
            <span>
              {' '}
              (бывшие:{' '}
              {person.exPartnerIds.map((exPartnerId) => (
                <InlinePerson id={exPartnerId} key={exPartnerId} />
              ))}
              )
            </span>
          )}
        </dd>

        <dt>Дети</dt>
        <dd>
          {person.childIds.map((childId) => (
            <InlinePerson id={childId} />
          ))}
        </dd>
      </dl>
      <div
        onClick={onCloseClick}
        style={{
          position: 'absolute',
          width: 16,
          height: 16,
          textAlign: 'center',
          lineHeight: '16px',
          padding: 8,
          top: 8,
          right: 8,
          cursor: 'pointer',
        }}
      >
        ✖
      </div>
    </div>
  );
});
